import React from 'react'
import { useTranslation } from 'react-i18next'
import { Div } from 'components/CommonStyles'
import FacebookSignupButton from 'components/FacebookSignupButton'
import GoogleSignupButton from 'components/GoogleSignupButton'
import LoginButton from 'components/LoginButton'
import EmailSignupButton from 'components/EmailSignupButton'

const SignUpButtons = ({ lang, source, isNotCN }) => {
  const { t } = useTranslation()
  const signUpLink = source
    ? `${process.env.NEXT_PUBLIC_TASTER_APP}?source=${source}&lang=${lang || 'en'}`
    : `${process.env.NEXT_PUBLIC_TASTER_APP}?lang=${lang || 'en'}`
  return (
    <React.Fragment>
      {isNotCN && (
        <Div mb={8}>
          <FacebookSignupButton
            href={`${
              process.env.NEXT_PUBLIC_TASTER_APP
            }/signin-with-facebook?source=${source}&lang=${lang || 'en'}`}>
            {t('components.home.signupButtons.facebookSignup')}
          </FacebookSignupButton>
        </Div>
      )}
      {isNotCN && (
        <Div mb={8}>
          <GoogleSignupButton
            href={`${
              process.env.NEXT_PUBLIC_TASTER_APP
            }/signin-with-google?source=${source}&lang=${lang || 'en'}`}>
            {t('components.home.signupButtons.googleSignup')}
          </GoogleSignupButton>
        </Div>
      )}
      <Div mb={8}>
        <EmailSignupButton href={signUpLink}>
          {t('components.home.signupButtons.emailSignup')}
        </EmailSignupButton>
      </Div>
      <LoginButton href={`${process.env.NEXT_PUBLIC_TASTER_APP}/sign-in?lang=${lang || 'en'}`}>
        {t('components.home.joinUs.login')}
      </LoginButton>
    </React.Fragment>
  )
}

export default SignUpButtons
