import styled from 'styled-components'
import { BasicSectionTitle } from 'components/CommonStyles'

export const SectionTitle = styled(BasicSectionTitle)`
  color: #a7a7a7;
  font-weight: 300;
`

export const Brand = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`
