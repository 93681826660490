import styled, { css } from 'styled-components'

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: 700;
  height: 60px;
  padding: 0 1rem;
  background-color: #8aba5c;
  text-decoration: none;
  color: #ffffff;
  max-width: 400px;
  margin: auto;
  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
  }
  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `};
`

export const GreenButton = styled(Button)`
  font-size: 1.125em;
  background-color: #8aba5c;

  &:hover {
    color: #ffffff;
    background-color: #7db14b;
    border-color: #7db14b;
  }

  &:active,
  &:focus {
    color: #ffffff;
    background-color: #709f44;
    border-color: #709f44;
  }
`

export const TasteReviewRewardButton = styled(Button)`
  font-size: 1.25em;
  height: 50px;
  background-color: #8aba5c;

  &:hover {
    color: #ffffff;
    background-color: #7db14b;
    border-color: #7db14b;
  }

  &:active,
  &:focus {
    color: #ffffff;
    background-color: #709f44;
    border-color: #709f44;
  }
`

export const CheckPriceButton = styled(Button)`
  height: 50px;
  font-size: 1.25em;
  background-color: #16477e;

  &:hover {
    color: #ffffff;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }

  &:active,
  &:focus {
    color: #ffffff;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
`

export const SignUpNowButton = styled(Button)`
  height: 60px;
  font-size: 1.75em;
  background-color: #16477e;

  &:hover {
    color: #ffffff;
    background-color: #123b68;
    border-color: #123b68;
  }

  &:active,
  &:focus {
    color: #ffffff;
    background-color: #0e2f53;
    border-color: #0e2f53;
  }
`

export const TrustpilotButton = styled(Button)`
  font-size: 1em;
  border-radius: 10px;
  height: 40px;
  color: #000;
  max-width: initial;
  background-color: #ffffff;
`

export const ButtonWhiteBlue = styled(Button)`
  font-size: 1.75em;
  color: #16477e;
  background-color: #ffffff;

  &:hover {
    color: #16477e;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }

  &:active,
  &:focus {
    color: #16477e;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
`

export const ButtonWhite = styled(Button)`
  font-size: 1.25rem;
  color: #3e4982;
  background-color: #ffffff;

  &:hover {
    color: #3e4982;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }

  &:active,
  &:focus {
    color: #3e4982;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
`

export default Button
