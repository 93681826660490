import Flex from 'styled-flex-component'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { Div, ResponsiveTextSize } from 'components/CommonStyles'
import { Section, Title, SubTitle, Img, TrustPilotImg, ReviewLink } from './styles'

const Reviews = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <Fade top>
        <ResponsiveTextSize>
          <Title id="trustpilot-reviews">{t('components.reviews.title')}</Title>
          <SubTitle>{t('components.reviews.subTitle')}</SubTitle>
        </ResponsiveTextSize>
      </Fade>
      <Fade bottom>
        <Div mt={9} mx="auto" maxWidth="540px">
          <Flex center>
            <ReviewLink
              rel="noreferrer noopener"
              target="_blank"
              href="https://g.page/r/CVPN_5-P9eY4EAE">
              <Img src="/images/google.svg" alt="google reviews" />
            </ReviewLink>
            <ReviewLink
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.facebook.com/FlavorWiki/reviews/">
              <Img src="/images/facebook.svg" alt="facebook reviews" />
            </ReviewLink>
            <ReviewLink
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.trustpilot.com/review/flavorwiki.com">
              <TrustPilotImg src="/images/trustpilot.svg" alt="trustpilot reviews" />
            </ReviewLink>
          </Flex>
        </Div>
      </Fade>
    </Section>
  )
}

export default Reviews
