import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { SignUpNowButton } from 'components/ButtonLink'
import {
  Section,
  SectionTitle,
  SectionDescription,
  Step,
  StepIcon,
  StepItem,
  StepContent,
  StepTitle,
  StepSubtitle,
  StepDescription
} from './styles'
import { BasicSectionFooter } from 'components/CommonStyles'

const HowItWorks = ({ source, lang }) => {
  const isRtl = lang === 'ar_EG' || lang === 'ar' || lang === 'ur' || lang === 'he_IL'
  const { t } = useTranslation()
  return (
    <Section>
      <Fade top>
        <SectionTitle id='how-it-works'>{t('components.home.howItWorks.title')}</SectionTitle>
        <SectionDescription>{t('components.home.howItWorks.description')}</SectionDescription>
      </Fade>
      <Step full justifyAround wrap>
        <StepItem basis='277px'>
          <Fade left>
            <StepIcon src='/images/step1.svg' alt='flavorwiki' />
            <StepContent>
              <StepTitle isRtl={isRtl}>{t('components.home.howItWorks.step1Title')}</StepTitle>
              <StepSubtitle isRtl={isRtl}>
                {t('components.home.howItWorks.step1Subtitle')}
              </StepSubtitle>
              <StepDescription isRtl={isRtl}>
                {t('components.home.howItWorks.step1Description')}
              </StepDescription>
            </StepContent>
          </Fade>
        </StepItem>
        <StepItem basis='277px'>
          <Fade top>
            <StepIcon src='/images/step2.svg' alt='flavorwiki' />
            <StepContent>
              <StepTitle isRtl={isRtl}>{t('components.home.howItWorks.step2Title')}</StepTitle>
              <StepSubtitle isRtl={isRtl}>
                {t('components.home.howItWorks.step2Subtitle')}
              </StepSubtitle>
              <StepDescription isRtl={isRtl}>
                {t('components.home.howItWorks.step2Description')}
              </StepDescription>
            </StepContent>
          </Fade>
        </StepItem>
        <StepItem basis='277px'>
          <Fade bottom>
            <StepIcon src='/images/step3.svg' alt='flavorwiki' />
            <StepContent>
              <StepTitle isRtl={isRtl}>{t('components.home.howItWorks.step3Title')}</StepTitle>
              <StepSubtitle isRtl={isRtl}>
                {t('components.home.howItWorks.step3Subtitle')}
              </StepSubtitle>
              <StepDescription isRtl={isRtl}>
                {t('components.home.howItWorks.step3Description')}
              </StepDescription>
            </StepContent>
          </Fade>
        </StepItem>
      </Step>
      <Fade top>
        <BasicSectionFooter center>
          <SignUpNowButton
            href={`${process.env.NEXT_PUBLIC_TASTER_APP}?source=${source}&lang=${lang || 'en'}`}
            block>
            {t('components.home.howItWorks.signUpNow')}
          </SignUpNowButton>
        </BasicSectionFooter>
      </Fade>
    </Section>
  )
}

export default HowItWorks
