import styled, { css } from 'styled-components'
import Flex, { FlexItem } from 'styled-flex-component'
import { BasicSection, BasicSectionTitle, BasicSectionDescription } from 'components/CommonStyles'

export const Section = styled(BasicSection)`
  text-align: center;
`

export const SectionTitle = styled(BasicSectionTitle)`
  color: #8aba5c;
`

export const SectionDescription = styled(BasicSectionDescription)`
  color: #a7a7a7;
  margin-bottom: 2.5rem;
`

export const Step = styled(Flex)`
  max-width: 1200px;
  margin: auto;
`

export const StepIcon = styled.img`
  width: auto;
  height: 10rem;
  margin: auto;
`

export const StepItem = styled(FlexItem)`
  text-align: center;
  margin: 20px;
`

export const StepContent = styled.div`
  margin-top: 2.5rem;
`

export const StepTitle = styled.div`
  text-align: center;
  font-size: 1.75rem;
  color: #a7a7a7;
  font-weight: 300;
  ${({ isRtl }) =>
    isRtl &&
    css`
      text-align: right !important;
    `};
`

export const StepSubtitle = styled(StepTitle)`
  font-weight: 400;
`

export const StepDescription = styled(StepTitle)`
  font-size: 20px;
`
