import styled from 'styled-components'

export const Hero = styled.section`
  min-height: 140vh;
  background-image: url('/images/PageBanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 1.5rem;
  @media screen and (min-width: 992px) {
    padding: 5rem 1.5rem;
    min-height: 100vh;
  }
`

export const AppearOnMobile = styled.div`
  display: block;

  @media screen and (min-width: 992px) {
    display: none;
  }
`

export const DisappearOnMobile = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
    margin-top: 3rem;
  }
`
