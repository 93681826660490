import { useTranslation } from 'react-i18next'
import Flex from 'styled-flex-component'
import { Div } from 'components/CommonStyles'
import ReviewButton from 'components/ReviewButton'
import SignUpButtons from '../SignUpButtons'
import { FB_REVIEW, TP_REVIEW, GOOGLE_REVIEW } from 'utils/constants'
import { Item, Container, Title, Subtitle, WhatUsersSay, ReviewButtonsWrapper } from './styles'

const JoinUs = ({ lang, source }) => {
  const isRtl = lang === 'ar_EG' || lang === 'ar' || lang === 'ur' || lang === 'he_IL'
  const isNotCN = lang !=='cn'
  const { t } = useTranslation()
  return (
    <Item>
      <Div mb="40px">
        <ReviewButtonsWrapper>
          <Flex justifyEnd>
           { isNotCN && <ReviewButton href={GOOGLE_REVIEW} icon="google" /> }
           { isNotCN && <ReviewButton href={FB_REVIEW} icon="facebook" /> }
            <ReviewButton href={TP_REVIEW} icon="trustpilot" />
          </Flex>
          <Div mt={2}>
            <WhatUsersSay>{t('components.home.joinUs.whatUsersSay')}</WhatUsersSay>
          </Div>
        </ReviewButtonsWrapper>
      </Div>
      <Container>
        <Div pb="30px">
          <Title isRtl={isRtl} mx="auto" mb={4}>
            {t('components.home.joinUs.title')}
          </Title>
          <Subtitle isRtl={isRtl} mx="auto">
            {t('components.home.joinUs.subTitle')}
          </Subtitle>
        </Div>
        <SignUpButtons source={source} isNotCN={isNotCN} lang={lang} />
      </Container>
    </Item>
  )
}

export default JoinUs
