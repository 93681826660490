import styled from 'styled-components'
import { BasicSection, BasicSectionTitle } from 'components/CommonStyles'

export const Section = styled(BasicSection)`
  background-color: transparent;
`

export const Title = styled(BasicSectionTitle)`
  color: #a7a7a7;
  font-weight: 300;
`

export const ReviewLink = styled.a`
  margin-right: 1rem;
  margin-left: 1rem;
  transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    transform: translate3d(0, -0.25rem, 0);
  }
  @media screen and (min-width: 768px) {
    margin-right: 3rem;
    margin-left: 3rem;
  }
`

export const SubTitle = styled.h3`
  font-size: 1.75em;
  font-weight: 300;
  text-align: center;
  color: #a7a7a7;
`

export const Img = styled.img`
  height: 100%;
  width: 100%;
  max-height: 50px;
  min-width: 80px;
  @media screen and (min-width: 768px) {
    max-height: 150px;
    min-width: 91px;
  }
`

export const TrustPilotImg = styled(Img)`
  @media screen and (min-width: 768px) {
    min-width: 120px;
  }
`
