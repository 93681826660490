import FBButton, { IconWrapper, Text } from './styles'

const FacebookSignupButton = ({ children, href }) => {
  return (
    <FBButton href={href}>
      <IconWrapper>
        <img src="/icons/facebook-logo.png" alt="Facebook" />
      </IconWrapper>
      <Text>{children}</Text>
    </FBButton>
  )
}

export default FacebookSignupButton
