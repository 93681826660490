import axios from 'axios'

// GET request
export const GET = (url, options = {}) => {
  const headers = {}

  return axios.get(url, { headers }).catch(() => {})
}

export const POST = (url, formData, options = {}) => {
  const headers = {}

  if (options.useAuthorization) {
    const token = cache.getItem('flavorwiki-taster-token')
    headers['Authorization'] = `Bearer ${token}`
  }

  return axios.post(url, formData, { headers }).catch(() => {})
}

// POST request
// PUT request
// DELETE request
