import styled from 'styled-components'
import { BasicSection, BasicSectionSubTitle } from 'components/CommonStyles'

export const Section = styled(BasicSection)`
  background-color: #8aba5c;
  text-align: center;
`

export const SectionSubTitle = styled(BasicSectionSubTitle)`
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 3.75rem;
`

export const Button = styled.a`
  display: inline-block;
  color: #8aba5c;
  background-color: #ffffff;
  border-color: #ffffff;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 1.125rem 3rem;
  max-width: 400px;
  width: 100%;

  &:hover {
    color: #8aba5c;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }

  &:active,
  &:focus {
    color: #8aba5c;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
`
