import styled from 'styled-components'
import ButtonLink from 'components/ButtonLink'

const GoogleButton = styled(ButtonLink)`
  position: relative;
  height: 55px;
  max-width: 332px;
  color: #575757;
  background-color: #ffffff;
  font-size: 1em;
  text-align: center;
  border-radius: 50px;
  margin: auto;

  &:hover {
    color: #575757;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }

  @media screen and (max-width: 400px) {
    font-size: 0.875em;
  }

  @media screen and (max-width: 321px) {
    font-size: 0.75em;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  left: 6%;
  top: 50%;
  transform: translate(-7%, -50%);
  img {
    width: 26px;
    height: 26px;
  }
`

export const Text = styled.div`
  margin-left: 2.5em;
`

export default GoogleButton
