import styled, { css } from 'styled-components'
import { Div } from 'components/CommonStyles'

export const Container = styled.div`
  font-size: 16px;
  text-align: center;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1200px) {
    max-width: 511px;
  }

  @media screen and (min-width: 992px) {
    max-width: 450px;
    margin-right: 20px;
    padding-top: initial;
  }
`

export const Title = styled(Div)`
  font-size: 2.125em;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  ${({ revert }) =>
    revert &&
    css`
      color: #474a5a;
    `};
  ${({ isRtl }) =>
    isRtl &&
    css`
      text-align: right !important;
    `};

  @media screen and (min-width: 992px) {
    text-align: left;
  }
`

export const Subtitle = styled(Div)`
  font-size: 1.5em;
  color: #e8e8e8;
  text-align: center;
  ${({ revert }) =>
    revert &&
    css`
      color: #474a5a;
    `};

  ${({ isRtl }) =>
    isRtl &&
    css`
      text-align: right !important;
    `};

  @media screen and (min-width: 992px) {
    text-align: left;
  }
`

export const Footer = styled(Subtitle)`
  font-size: 1.25em;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
`

export const TextWhite = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  span {
    display: inline-block;
    margin-left: 0.5rem;
  }
`
