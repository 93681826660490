import styled from 'styled-components'
import { BasicSection, BasicSectionTitle, BasicSectionDescription } from 'components/CommonStyles'

export const Section = styled(BasicSection)`
  background-color: #8aba5c;
  text-align: center;
`

export const SectionTitle = styled(BasicSectionTitle)`
  color: #ffffff;
`

export const SectionDescription = styled(BasicSectionDescription)`
  color: #ffffff;
  margin-left: 3rem;
  margin-right: 3rem;
`
