import Button, { IconWrapper } from './styles'

const ReviewButton = ({ href, icon }) => {
  return (
    <Button target="_blank" href={href}>
      <IconWrapper>
        <img src={`/images/${icon}.svg`} alt={icon} />
      </IconWrapper>
    </Button>
  )
}

export default ReviewButton
