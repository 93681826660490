import GoogleButton, { IconWrapper, Text } from './styles'

const GoogleSignupButton = ({ children, href }) => {
  return (
    <GoogleButton href={href}>
      <IconWrapper>
        <img src="/images/google.svg" alt="Google" />
      </IconWrapper>
      <Text>{children}</Text>
    </GoogleButton>
  )
}

export default GoogleSignupButton
