import React from 'react'
import Fade from 'react-reveal/Fade'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Container } from 'components/CommonStyles'
import { BasicSection } from 'components/CommonStyles'
import { SectionTitle, Brand, Image } from './styles'

const SETTINGS = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
}

export const GlobalBrandsMarquee = React.memo(() => {
  const { t } = useTranslation()

  return (
    <BasicSection>
      <Container>
        <Fade top>
          <SectionTitle>{t('components.home.globalBrands.title')}</SectionTitle>
        </Fade>

        <Slider {...SETTINGS}>
          <Brand>
            <Image src="/images/brands/Beyond-2.png" alt="Beyond Meat" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Bunge-5.png" alt="Bunge" />
          </Brand>
          <Brand>
            <Image src="/images/brands/CHRHansen-3.png" alt="Christian Hansen" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Cocacola-3.png" alt="Coca Cola" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Dupont-3.png" alt="Dupont" />
          </Brand>
          <Brand>
            <Image src="/images/brands/GenneralMils-2.png" alt="General Mills" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Givaudan-3.png" alt="Givaudan" />
          </Brand>
          <Brand>
            <Image src="/images/brands/HelloFresh-2.png" alt="Hello Fresh" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Impossible-2.png" alt="Impossible" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Kelloggs-3.png" alt="Kellogg's" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Landolakes-2.png" alt="Lando Lakes" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Mars-3.png" alt="Mars" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Metro-2.png" alt="Metro" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Mondelex-3.png" alt="Mondelez" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Myco-2.png" alt="Myco Technology" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Nestle2-2.png" alt="Nestle" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Sweetgreen-2.png" alt="Sweetgreen" />
          </Brand>
          <Brand>
            <Image src="/images/brands/thermomix.png" alt="Thermomix" />
          </Brand>
          <Brand>
            <Image src="/images/brands/Vindara-3.png" alt="Vindara" />
          </Brand>
        </Slider>
      </Container>
    </BasicSection>
  )
})

export default GlobalBrandsMarquee
