import styled, { css } from 'styled-components'
import { Div } from 'components/CommonStyles'

/* Start - Join FlavorWiki Section */
export const Container = styled.div`
  border-radius: 20px;
  padding: 2rem 20px 3rem 20px;
  background-color: #efefef;
  font-size: 16px;
  @media screen and (min-width: 992px) {
    padding: 30px;
    padding-top: 16px;
  }
`

export const Item = styled.div`
  width: 100%;

  @media screen and (min-width: 992px) {
    max-width: 450px;
  }

  @media screen and (min-width: 1040px) {
    margin-left: 5rem;
  }

  @media screen and (min-width: 1200px) {
    max-width: 462px;
  }
`

export const Title = styled(Div)`
  font-size: 1.8125em;
  font-weight: 700;
  text-align: center;
  max-width: 337px;
  color: #8aba5c;
  ${({ isRtl }) =>
    isRtl &&
    css`
      text-align: right !important;
    `};
`

export const Subtitle = styled(Title)`
  font-size: 1.625em;
  color: #474a5a;
  max-width: 300px;
`

export const ReviewButtonsWrapper = styled.div`
  max-width: 300px;
  margin-left: auto;
`

export const WhatUsersSay = styled.h5`
  font-size: 0.875em;
  display: block;
  font-weight: 300;
  letter-spacing: 3.08px;
  text-align: center;
  text-transform: uppercase;
  color: #a7a7a7;
`
/* End - Join FlavorWiki Section */
