import { useTranslation } from 'react-i18next'
import { GreenButton as Button } from 'components/ButtonLink'
import { Div } from 'components/CommonStyles'
import { Container, Title, Subtitle, Footer, TextWhite, ButtonWrapper } from './styles'

const Details = ({ revert, lang }) => {
  // console.log(lang)
  const { t } = useTranslation()
  const isRtl = lang === 'ar_EG' || lang === 'ar' || lang === 'ur' || lang === 'he_IL'
  // console.log(isRtl)

  return (
    <Container>
      <Title isRtl={isRtl} revert={revert} mb="2rem">
        {t('components.home.details.title')}
      </Title>
      <Subtitle isRtl={isRtl} revert={revert} mb="2rem">
        {t('components.home.details.subTitle')}
      </Subtitle>
      <Footer isRtl={isRtl} revert={revert} mb="2rem">
        {t('components.home.details.footer')}
      </Footer>
      <ButtonWrapper>
        <Div mb={6}>
          <Button href="#how-it-works" block>
            {t('components.common.learnMore')}
          </Button>
        </Div>
        <Div mb={6}>
          <TextWhite block>
            <img src="/icons/paypal.png" width={52} height={29} />
            <span>{t('components.home.details.paypalText')}</span>
          </TextWhite>
        </Div>
      </ButtonWrapper>
    </Container>
  )
}

export default Details
