import Flex from 'styled-flex-component'
import { Div } from 'components/CommonStyles'
import JoinUs from 'components/JoinUs'
import Details from 'components/LandingDetails'
import { Container } from 'components/CommonStyles'
import { Hero, AppearOnMobile, DisappearOnMobile } from './styles'

const Content = ({ numberOfReviews, lang, source }) => {
  // console.log(source)
  return (
    <Hero>
      <Container>
        <AppearOnMobile>
          <Flex justifyCenter wrap>
            <Div mt="5em" width="100%">
              <JoinUs source={source} lang={lang} numberOfReviews={numberOfReviews} />
            </Div>
            <Div mt="2em">
              <Details lang={lang} />
            </Div>
          </Flex>
        </AppearOnMobile>
        <DisappearOnMobile>
          <Flex justifyCenter wrap>
            <Details lang={lang} />
            <JoinUs source={source} lang={lang} numberOfReviews={numberOfReviews} />
          </Flex>
        </DisappearOnMobile>
      </Container>
    </Hero>
  )
}

export default Content
