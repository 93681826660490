import Fade from 'react-reveal/Fade'
import { useTranslation } from 'react-i18next'
import { Section, SectionSubTitle, Button } from './styles'

const SectionNewToFlavorwiki = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <Fade top>
        <SectionSubTitle>{t('components.home.newToFlavorwiki.title')}</SectionSubTitle>
        <Button href="/faq">{t('components.common.faqLong')}</Button>
      </Fade>
    </Section>
  )
}

export default SectionNewToFlavorwiki
