import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { Container } from 'components/CommonStyles'
import { Section, SectionTitle, SectionDescription } from './styles'

const RealFeedback = () => {
  const { t } = useTranslation()
  return (
    <Section>
      <Container>
        <Fade top>
          <SectionTitle>{t('components.home.yourOpinionMatters.title')}</SectionTitle>
          <SectionDescription>{t('components.home.yourOpinionMatters.content')}</SectionDescription>
        </Fade>
      </Container>
    </Section>
  )
}

export default RealFeedback
