import EmailIcon from 'public/icons/email.svg'
import EmailButton, { IconWrapper, Text } from './styles'

const EmailSignupButton = ({ children, href }) => {
  return (
    <EmailButton href={href}>
      <IconWrapper>
        <EmailIcon />
      </IconWrapper>
      <Text>{children}</Text>
    </EmailButton>
  )
}

export default EmailSignupButton
