import styled from 'styled-components'
import ButtonLink from 'components/ButtonLink'

const Button = styled(ButtonLink)`
  position: relative;
  height: 36px;
  width: 84px;
  max-width: 332px;
  color: #575757;
  background-color: #ffffff;
  font-size: 1em;
  text-align: center;
  border-radius: 10px;
  margin: auto;

  &:hover {
    color: #575757;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
  }
`

export const IconWrapper = styled.div`
  img {
    width: 35px;
    height: 24px;
  }
`

export default Button
